
button{
 border: 0;
 background: none;
 box-shadow: none;
 border-radius: 0px;
cursor: pointer;

}
.btn{
  text-transform: capitalize;
  background-color: orange;
color:white;
padding:4px;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
#root{
  min-height: 600px;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#addBtn{
  position:fixed;
  bottom:0;
  right:0;
  font-size:20px;
  height:100px;
  width:100%;
}
#addBtn > img {
  float:right;
  height:60px;
  margin:30px;
}
#add_target{

  position: fixed;
  right:11px;
  top:45px;
  line-height: 20px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  z-index: 30;
  color: white;
  background-color: orange;
}
.add_target_modal{
  position:fixed;
  top:40%;
  height:80px;
  width:200px;
  padding:0;
  z-index: 44;
  text-align: center;
  font-size: 26px;
}
.add_target_input{
  height:80px;
  width:200px;
  padding:0;
  z-index: 44;
  text-align: center;
  font-size: 26px;
}
.back{
  width:50px;
  height:25px;
  position:fixed;
  top:10px;
  left:5px;
  z-index: 3;
}
.back_btn{
  height:25px
}
#back_from_messageUI{
  position:relative;
  top:10px;
  left:10px;
}


.block{
  height:80px;
  width:100px;
  margin:40px auto 0px auto ;
  max-width: 100px;
  font-size: 15px;
  padding:0;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 7px;
  color:orange;
  background-size: cover;
  padding:10px;
  line-height: 60px;
  overflow: hidden;
  border: 2px solid orange;
  transition: color .5s;
}
.block:hover {
  color: #fff;
  cursor: pointer;
  background-color: orange;

}
.blockImg{
  width:80%;
  z-index: 1;
  position: absolute;
  text-align: center;
  opacity: 0.1;
  top:0;
  left:10%;
}
.block_feature{
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: black;
  z-index:2 ;
 margin-top:0;
  font-size:13px;

}
.block_slider{
  overflow: auto;
  white-space: nowrap;
}
.call_direction{
  display: inline-block;
  margin:5px;
  position:absolute;
  height:50px;

  top:0;
  left:0;
}
.call_direction img{
  height:50px;
}
.call_info {
  width:100%;
  height:40px
}
.callStatus {
  display: inline-block;
  margin-left: 5px;
}
#call_entry_disposition {
  width: 5%;
}
#call_entry_source {
  width: 30%;
}
#call_entry_destination {
  width: 30%;
}
#call_entry_timestamp{
  width: 30%;
}
#call_entry_duration {
  width: 5%;
}
.table_row td{
  width:20%
}
.table_row:nth-child(even) {
  background: rgb(252, 252, 252);
}
.table_row:nth-child(odd) {background: #FFF}
#call_logs{
  height:calc( 100% - 100px) ;
  width:100%;
  background-color: white;
  position: fixed;
  padding-top:40px;
  top:0;
  left:0;
  bottom:10px;
  z-index: 2;
  overflow-y: scroll;
}
#call_logs table {
width:100%
}
#change_password_btn{
  height:35px;
  
}
#clear_text{
  width:40px;
  z-index: 10;
}
#clear_text img{
  width:40px;
}
#connect{
  width:270px;
  display:block;
  margin: 15px auto;
  text-align: center;
}
.connect{
  float: right;

}
.connect button{
  margin: 0px 0px /* any margining breaks the design, need to revisit*/
}
#connectCall img{ 
  height:"50px";
}
#connectHangup{
  float: right;
}
.contact{
  height: 65px;
  font-size: 25px;
  border-bottom: 1px solid rgba(192, 192, 192, 0.473);
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
  margin: 0 15px;
}
.contact_or_user{
  display: inline-block;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc( 100% - 120px );
  overflow:hidden
}
.contact:hover{
  background-color: rgba(192, 192, 192, 0.425);
}

.contact .status{
  display: inline-block;
  width:10%;
  text-align: center;

}
.contact .user_name{
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contact .connect {
  display: inline-block;
  text-align: right;
}
.contacts_container {
  width:100%;
  position: relative;
  top:60px;
  height:calc(100vh - 130px)
}
.contacts_selected{
  background-color: orange;
  border-top-left-radius:50%;
  border-bottom-left-radius:50%;
}
#conversation_body{
  height: calc(100% - 130px);
  overflow-y: scroll;
  overflow-x: hidden;

}
.conversation_tab{
  width:300px;
  height:70px;
  background-color: rgb(255, 255, 255);
  
  margin:10px auto 20px   ;
  border:orange 2px solid;
  border-radius: 10px;
  position:relative;
}
.conversation_tab button{
  width:100%;
  height:100%;
  display: block;
  text-align: center;
  margin:auto;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 2px;
  overflow: hidden;
  
}
.conversation_tab button:hover, .selected_conversation
{ 
  color: #fff;
  cursor: pointer;
  background-color: orange ;
  transition: color .5s;

  
}
#conversation_tabs{
  overflow-y: auto;
  height:calc( 100% - 50px);
}
.conversation_tab_number {
  color:"orange";
  font-size:10px;
}
.destination{
  text-align: center;
  line-height: 50px;
  max-height: 50px;
}
.destination .contact_or_user{
  overflow:unset !important;
}
.destination_list{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  max-height: 35px;
  opacity: 1;
  overflow:clip;
  transition: opacity 300ms ease;
}

#dialPadInput{
  display: block;
  margin:auto;
}
.dialPadLetters{
position: relative;
bottom:20px;
font-size: 12px;
height:20px;
margin-bottom: 0;
color: rgb(253, 146, 24);
}
.dialPadNumbers{
  margin-bottom:15px;
}
.dialPage{
  display: block;
  position: relative;
  top:50px
}
.dial_button
{ position:relative;
  background: #fff;
  border: 1px solid #999;
  color:#626a80;
  float: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  height:70px;
  padding: 0;
  text-align: center;
  width: 70px;
  cursor:pointer
}
.dial_pad 
{
  text-align: center;
  margin:auto;
  height:280px;
  max-height:100%;
  width:210px;
  max-width:100%;
  position: relative;
  margin-top: 30px;
}
.directory{
  text-align: center;
}
.directory .user_name{
  text-align: left;
  text-transform: capitalize;
}
#directoryHeader{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  background-color: white;
  height:75px;
  z-index: 5;


  -moz-box-shadow:     1px 1px 4px 1px rgba(192, 192, 192, 0.507);
  -webkit-box-shadow:voicemail_app  1px 1px 4px 1px rgba(192, 192, 192, 0.507);
  box-shadow:         1px 1px 4px 1px rgba(192, 192, 192, 0.507);
}
#directory_search_input{
  z-index: 2;
  width: 200px;
  font-size: 22px;
  text-align: left;
  padding-left: 10px;
}
.directory_type {
  display: inline;
  height: 50px;
  width: 60px;
  margin: 10px;
  font-size: 17px;
  outline: none;


}
#exit_conversation{
  width:50px;
  height:50px;
  float: left;
}
.fixed {
  position:fixed;
  background:'brown';
  top:0;
  left:0;
}
#Footer{
  height:50px;
  width:100vw;
  position:fixed;
  bottom:0;
  left:0;
  text-align: center;
  background-color: white;
  padding-top:5px;

  -moz-box-shadow:     1px 1px 4px 1px rgba(192, 192, 192, 0.507);
  -webkit-box-shadow:  1px 1px 4px 1px rgba(192, 192, 192, 0.507);
  box-shadow:         1px 1px 4px 1px rgba(192, 192, 192, 0.507);

}
#Footer > button {
  margin:0 12px;
}
.answerBtn,.hangupBtn , .holdBtn, .muteInBtn{
  position: relative;
  width:50px;
  height:50px;
  margin:0px 10px;
}
.answerBtn img, .hangupBtn img , .holdBtn img, .muteInBtn img{
  width:40px;
  height:40px;
}
#Header{
  height:50px;
  width:100%;
  position:absolute;
  font-size: 50px;
  top:0;
  left:0;
  text-align: center;
}
.hidden {
  display: none;
}
.home_block_notification{
  font-size: 40px;
  max-height: 40px;
  position:relative;
  margin:0;
  z-index: 2;
}
.incomingCallStatus {
  padding:0 10px;
  width:100%;
  display: inline-block;

}
.input{
  width:250px;
  margin:10px 0;
  font-size: 30px;
  text-align: center;
  color: #4d5364;
  position: relative;
  height: 35px;
  }
#island {
  height: 50px;
}
#island .notifier{
  position: relative;
  float: right;
  height:20px;
  right:10px;
  top:10px;
  z-index: 100;
  margin: 0px 5px;
  display: inline-block;
}
#loading_login{
  height:25px;
  width:25px;
  margin-top:10px;
}
#login {
  margin:auto;

}
#login_btn{
  width:75px;
  height:40px;
  background-color: orange;
  border:none;
  cursor:pointer;
  color:white;
  margin-top: 15px;
}

#login_background{
  text-align: center;
  height: 100px;
}
#login_background img {
  position: relative;
  top:25px;
  height: 50px;
}
#login_error{
  font-size: 10px;
  color:rgb(158, 37, 37); 
}
#login_form{
  text-align:center;
  width:300px;
  margin:auto ;

}

.login_img{
  position:relative;
  height:25px;
  width:25px;
  padding:10px;
  top:15px;
  
}
.login_input{
  border: orange;
  height:50px;
  font-size: 17px;
  margin-top:10px;
  text-align: center;
  border:none;
}

#login_submit{
display: block;
background-color: orange;
color: white;
padding:15px;
margin:auto;
margin-top:40px;
width:200px;
border-radius: 40px;
font-size: large;

}
.log_out {
  background-color: orange;
  color: white;
  border-radius: 5px;
  padding:8px;
  transition:1s;
}
.log_out:hover {
  background-color: black;
  color: orange;
}
#messaging_app,#voicemail_app{
  position: fixed;
  left:0;
  top:0;
  height:100vh;
  width:100vw;
  z-index: 24;
  background-color: white;
}
.messaging_nav_contact_span :only-child {
  line-height: 50px;
}
#messaging_ui{

  right:auto;
  height:100%;
  width:100%;
  z-index: 1;
  background-color: white;
}
#messaging_ui_nav {
  height: 50px;
  width:100%;
  position: relative;
  text-align: center;
  background-color: white;
  padding-top: 5px;
  box-shadow: 1px 1px 4px 1px rgb(192 192 192 / 51%);
  display:flex;
 
}
#mic{
  width:40px;
  height:40px;

}
#conference_btn {
  background: none;
  padding:0
}
#conference_btn img{
  height:20px;
}
#mix_calls_modal{
  position: fixed;
  top: 40%;
  right: 40%;
  background-color: white;
  z-index: 50;
  border: 1px solid orange;
  border-radius: 3px;
  padding: 10px;
}

.modal{
  position:fixed;
  background:white;
  top:40%;
  left:calc(50% - 100px);
}
.my_text {
  text-align: right;
  position: relative;
  margin-right: 10px;
 
}
.my_text p{
  display:inline-block;
  padding:10px;
  border-radius: 10px;
  border: 1px solid orange;
  color:#414757;
  max-width: calc(100% - 50px);
  text-align: left;
  overflow-wrap: break-word;
  margin: 7px 10px;
  max-width:80%;
  font-weight: 500;

}



.nav{
position:relative;
  height:40px;
  width:40px;
  cursor:pointer;
  margin:0 5px;
  

}
#new_contact {
  position: fixed;
  bottom:65px;
  right:20px;
  padding:10px 5px;
  background-color: orange;
  color:white;
  border-radius: 5px;
}
#new_contact_form {
  position: fixed;
  top:20%;
  height:160px;
  width:220px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid orange ;
  border-radius: 5px;
  background-color: white;
}
.new_contact_input {
  display: block;
  margin: 10px auto;
  text-align: center;
  padding: 8px 0px;
  font-size: 16px;
  max-width: 80%;
}
#new_contact_add_btn{
  padding: 10px;
  width: 100px;
  border-radius: 5px;
}
.new_messages{
  color: white;
  background-color: orange;
  border-radius: 50%;
  padding:5px;
  display: inline-block;
  text-align: center;
  margin: auto;
  position: absolute;
  right:30px;
  top:5px;
  height:15px;
  width:15px;
  letter-spacing: 0px;
}
#new_message_icon{
  z-index: 96 !important;
  top:11px !important
}
.new_row{
  background-color: rgba(255, 179, 37, 0.603) !important;
}
.not_arrived{
  border-style: dashed !important;
}
#profile{
  text-align: center;

}
#profile_name,#select_outbound_number{
    height: 50px;
    line-height: 40px;
    font-size: 22px;
    width: 200px;
}
.profile_password{
  text-align: center;
  font-size: 10px;
  width:140px;
  height:30px
}

#scroll_bar{
  position: absolute;
  right:0;
  top:55px;
  height:calc( 100% - 147px );
  pointer-events: none;
  width:30px;
  background-color: white;
}
.selected_conversation .new_messages {
  background-color: white;
  color: orange;
}

#see_all_targets {
  position: relative;
  width: 150px;
  z-index: 20;
  top:15px;
}
#scroll_dot{
  position: absolute;
  pointer-events: none;
  top: 56px;
  right: 5px;
}
.searchDiv{
  width:100%;
  text-align: center;
  
}
#send_icon_button{
  height:30px;
  width:30px;
  outline: none;
  position:absolute;
  padding:0;
  bottom:35px;
  right:12px;
}
#send_icon_img{
  height:30px;
  width:30px;
  padding:0;

}
#send_msg_btn {
  position:relative;
  float:right;
  height:30px;
  width:30px;
  padding:0;
  top: 7px

}
#send_msg_form{
  position: absolute;
  bottom:0px;
  width:95%;
  left:2%;
  height:60px;
  margin: auto;
}
#send_text_input {
  width: calc(100% - 70px);
  height:20px;
  outline: none;
  overflow: hidden;
  float:left;
  border-radius: 10px;
  padding:15px;
  border: 1px solid #979eaf98;
  color: rgb(51, 53, 56);
  font-size: 20px;
  position:absolute;
  bottom:5px;
}
.session{
  position:fixed;
  z-index: 25;
  width:99%;
  left:1px;
  max-width: 500px;
  height:100px;
  background-color: white;
  border:1px solid orange;
  display: inline-block;
  cursor: move;
  text-align: center;
}
.session div{
  text-align: center;
}
.show_list:hover ~ .destination_list{
  max-height: 500px;
  z-index: 300;
  overflow: visible;
  background-color: white;
}

#slide_down{
  height: 48px;
  width:48px;
  position: absolute;
  top:60px;
  left:calc( 50% - 25px);
}
.status{
  position: absolute;
  width:10px;
  height:10px;
  right:10px;
  top:10px;
}

.statusOption{
  display: block;
  width: 100%;

}
.statusOptionImg{
  width:20px;
  height:20px;
  float: right;
  padding:0

}
#statusOptionSelected >img {
  position: relative;
  left:1px
}
#statusOptions{
  position: fixed;
  width:100px;
  height:60px;
  right:8px;
  top:30px;
  z-index: 150;
  padding:0;
  background:white;
}
#statusOptionSelected{
  border:orange 1px solid;
}
.statusImg{
  height:10px;
  position: relative;
  bottom: 7px;
}
.team_selected{
  background-color: orange;
  border-top-right-radius:50% ;
  border-bottom-right-radius:50% ;

}
#tabs_nav {
text-align:center;
}
.team_user{
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
#messaging_ui_nav .team_user{
  position: relative;
  float: left;
  line-height: 50px;
  text-align: center;
}
.text_bubble{
  white-space: pre-wrap;
}
.their_text p {
  display:inline-block;
  background-color: rgb(248, 248, 248);
  padding:10px;
  border-radius: 10px;
  border: 1px solid #979eaf48;
  color:#414757;
  max-width: calc(100% - 50px);
  text-align: left;
  overflow-wrap: break-word;  
  margin: 7px 10px;
  margin-right: 50px;
  font-weight: 500;
  max-width: 90%;


}
.timer{
  display: inline-block;
  margin:5px;
  position:absolute;
  top:0;
  right:0;
}
.timestamp{
  display: block;
  font-size: 12px;
  font-weight:lighter;
  padding-top:3px;

}
#transfer_call_modal{
  height:200px;
  width:200px;
  z-index: 30;
  border: orange 1px solid;
  padding: 5px;
}
.toggle_team_contacts{ 
  width:170px;
  height: 50px;
}
.update_password_field {
  display: block;
  margin: 5px auto  ;
}







@media only screen and (max-width: 700px) {
  .open_conversation{
    display:none
  }
  #clear_text{
    display:none
  }
  #clear_img{
    display: inline;
  }
}


@media only screen and (min-width: 700px) {
  #clear_img{
    display:none
  }
  #clear_text{
    display: inline;
  }
  #conversation_body{
    height: calc(100% - 70px);
  }
  #conversation_tabs{
    position:absolute;
    left:0;
    top:70px;
    height:calc( 100% - 50px);
    width: 330px;

  }
  .conversation_tab{

  margin:0 auto 20px  ;
  }
  
  #messaging_ui{
    display: block;
    height:calc( 100% - 70px);
    width: calc( 100vw - 330px) ;
    z-index: 25;
    background-color: white;
    position: absolute;
    top:70px;
    left:auto;
    right:-5px;
    border-top:orange  2px solid;
    border-left:orange  2px solid;
    border-radius: 10px;
  }
  #messaging_ui_nav{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 5px;
  top:-60px;
  box-shadow: none;
  position: absolute;

  }
  .conversation_body div{
    display: block;
    position: relative;
    width:100%
  }
 #tabs_nav {
  max-width:330px
 }
}

